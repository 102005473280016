.typography-text-header-2xl {
  @apply text-2xl leading-[28px] font-[300];
}

.typography-text-header-lg {
  @apply text-lg leading-[28px] font-[300];
}

.typography-text-base {
  @apply text-sm leading-6 font-normal;
}

.typography-text-base-v2 {
  @apply text-[11px] leading-[20px] font-normal text-black-v1;
}

.typography-text-base-v3 {
  @apply text-[16px] leading-[18px] font-[400] text-black-v1;
}

.typography-text-base-v4 {
  @apply text-[14px] leading-[18px] font-[400] text-black-v1;
}

.typography-text-base-v5 {
  @apply text-[12px] leading-[18px] font-[400] text-black-v1;
}
