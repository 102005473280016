@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Thin.ttf');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Light.ttf');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Regular.ttf');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./montserrat/Montserrat-Black.ttf');
    font-weight: 900;
    font-style: normal;
}

:root {
    --font-montserrat: 'Montserrat', sans-serif;
}
